"use client";

import { useCrypto } from "hooks";
import { useSearchParams } from "next/navigation";
import { useState, useEffect } from "react";

function useProvider() {
  const [provider, setProvider] = useState(null);
  const [token, setToken] = useState(null);
  const searchParams = useSearchParams();
  const { md5Hash } = useCrypto();

  useEffect(() => {
    const items = ["code", "provider", "token", "ref"];

    for (const item of items) {
      const value = searchParams.get(item);

      if (value) {
        const hash = JSON.stringify(
          item === "provider" ? md5Hash(value.toUpperCase()) : value
        );
        sessionStorage.setItem(`_bup.${item}`, hash);
      }
    }
  }, [searchParams, md5Hash]);

  useEffect(() => {
    const currentProvider = JSON.parse(sessionStorage.getItem("_bup.provider"));
    setProvider(providers.find((item) => item.id === currentProvider));
  }, [searchParams]);

  useEffect(() => {
    const currentToken = JSON.parse(sessionStorage.getItem("_bup.token"));
    setToken(currentToken);
  }, [searchParams]);

  return { provider, token };
}

const providers = [
  { id: "5f7c86228b190aa6f16fc438a0aa7b70", src: "cla.png" },
  { id: "43f2b0dd09b2b91146fe726dcc47381b", src: "b_chile.png" },
];

export default useProvider;
