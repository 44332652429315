import { useApolloClient } from "@apollo/client";
import { APP } from "consts";
import decodeToken from "contexts/auth/decodeToken";
import CryptoJS from "crypto-js";
import usePromoCode from "hooks/session/usePromo";
import { POST_SIGNUP } from "mutations/session";
import { useState } from "react";
import { getItem, openUrl, removeItem } from "utils";
import register from "utils/register";

function useSignup() {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [user, setUser] = useState({});
  const promoCode = usePromoCode();
  const next = getItem("_bup.next");

  const onSubmit = async (values, { setErrors, setValues }) => {
    if (values.view === "register") {
      const data = register.formatData(values);
      setErrors(data.errors);
      setUser(data.user);
      setValues(data.values);
      window.scrollTo(0, 0);
    } else {
      const variables = register.updateInfo(values, user, promoCode);
      setUser(variables);
      if (await signUp(variables)) {
        console.log(
          APP.websiteUrl +
            `/confirmacion?cjs=${CryptoJS.AES.encrypt(
              values.firstName,
              process.env.FIREBASE_API_KEY
            ).toString()}`,
          0,
          "_self"
        );
        removeItem("_bup.next");
        openUrl(
          (process.env.NODE_ENV === "production"
            ? APP.networkUrl
            : "http://localhost:8000/") + (next || "/app/profile/interests/"),
          0,
          "_self"
        );
        //setLoading(false);
      }
    }
  };

  const signUp = async (variables) => {
    try {
      setLoading(true);
      const { data } = await client.mutate({
        mutation: POST_SIGNUP,
        variables,
      });
      const { accessToken, refreshToken } = data?.signup?.tokens || {};
      decodeToken({ accessToken, refreshToken });
      return true;
    } catch (error) {
      setLoading(false);

      const gqlError = error.graphQLErrors?.[0].extensions;
      const message = gqlError?.message.includes("duplicate")
        ? "DUPLICATE"
        : gqlError.code;

      setError(message);
      console.log("error", message);
      return false;
    }
  };

  return {
    error,
    loading,
    onSubmit,
    promoCode,
  };
}

export default useSignup;
