import {
  Button,
  Icon,
  IconButton,
  Image,
  Link,
  Modal,
  Stack,
  Typography,
} from "components";
import { useConfig, useConsole } from "contexts";
import { useState } from "react";
import helper from "utils/helper";
import { openUrl } from "utils";

export default function Media({ children, src, video }) {
  const [open, setOpen] = useState(false);
  const { contact } = useConfig();
  const { screen } = useConsole();

  return (
    <Stack spacing={4} hidden={screen.laptop}>
      {children}
      <Image
        alt="form-media"
        src={src}
        onClick={() => setOpen(!open)}
        height={270}
        width={480}
      >
        {video && <IconButton icon={!open ? "play_arrow" : "pause"} />}
      </Image>
      <Stack spacing={2}>
        <Stack spacing={0.5}>
          <Typography variant="p">
            ¿Tienes dudas? Escríbenos al{" "}
            <Link href={helper.getWhatsappUrl(contact?.phone)}>
              <b>{helper.toReadablePhone(contact?.phone)}</b>
            </Link>
            .
          </Typography>
          <Typography variant="p">
            ¿Quieres saber más? Mira nuestro{" "}
            <Link id="AS-reelVideo" onClick={() => setOpen(!open)}>
              <b>video aquí</b>
            </Link>
            .
          </Typography>
        </Stack>
        <Button
          endIcon={<Icon name="whatsapp" variant="fb" />}
          onClick={() => openUrl(helper.getWhatsappUrl(contact?.phone))}
          size="large"
        >
          Contacto
        </Button>
      </Stack>
      <Modal open={open} onClose={() => setOpen(false)}>
        <iframe
          allow="autoplay"
          className="Bup-video Bup-reel"
          src="https://bit.ly/bup-reel"
        />
      </Modal>
    </Stack>
  );
}
