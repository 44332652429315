"use client";

import { Box, Grid, Stack, Typography } from "components";
import Head from "next/head";
import parser from "utils/parser";

import About from "./About";
import Media from "./Media";
import Topbar from "./Topbar";

function BrowserLayout({ children, metadata }) {
  return (
    <>
      <Head>
        <title>{metadata?.title}</title>
      </Head>
      <Box className="BupBrowser-root">
        <Topbar />
        <Box className="BupLogin-view" component="section">
          {children}
        </Box>
      </Box>
    </>
  );
}

export function MainGrid({ children, hidden, src, title, video }) {
  if (hidden) return null;

  return (
    <Grid spacing={{ xs: 4, md: 8, lg: 12 }} container>
      <Grid className="Bup-wrapper" lg={6} xs={12} item>
        <Media src={src} video={video}>
          <Typography variant="h2">{parser.toReactElement(title)}</Typography>
        </Media>
      </Grid>
      <Grid lg={6} xs={12} item>
        <Stack spacing={1.5}>
          {children}
          <About />
        </Stack>
      </Grid>
    </Grid>
  );
}

export default BrowserLayout;
