import { Box, Button, Icon } from "components";
import { useConsole } from "contexts";
import { APP } from "consts";

function InstallButton({ hidden, id, register }) {
  const { cpanel, screen, ...pwa } = useConsole();
  const { laptop, tablet } = screen;

  if (!pwa.isReadyForInstall) return null;

  return (
    <Box className="BupTopbar-backdrop">
      <Button
        className="BupTopbar-install"
        color="inherit"
        id={id}
        hidden={
          hidden ||
          (laptop && !cpanel.values.expanded) ||
          process.env.NODE_ENV === "development"
        }
        loading={pwa.isInstalling}
        onClick={pwa.downloadPWA}
        startIcon={<Icon name="download" />}
        variant="outlined"
      >
        Instalar {tablet && !register && APP.alias}
      </Button>
    </Box>
  );
}

export default InstallButton;
