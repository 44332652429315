import { useLazyQuery } from "gql";
import { useRouter } from "next/router";
import { CHECK_CODE } from "queries/session";
import { useEffect, useState } from "react";
import { getItem } from "utils";

export default function usePromoCode() {
  const [checkCode, { data, loading }] = useLazyQuery(CHECK_CODE);
  const [referralCode, setReferralCode] = useState("");
  const [signUpCode, setSignUpCode] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [provider, setProvider] = useState(null);
  const [hidden, setHidden] = useState(false);
  const [token, setToken] = useState(null);
  const [error, setError] = useState(false);
  const { query } = useRouter();

  const onBlur = (e) => {
    const id = e.target.value.toUpperCase();
    setSignUpCode(id);
    setError(false);

    if (id.length < 4) return;
    if (id.length < 8) {
      setError(true);
    } else {
      checkCode({ id });
    }
  };

  useEffect(() => {
    const code = getItem("_bup.code", sessionStorage);
    const data = getItem("_bup.provider", sessionStorage);
    const referral = getItem("_bup.ref", sessionStorage);
    const token = getItem("_bup.token", sessionStorage);

    let provider = providers.find((p) => p.id === data)?.name ?? "";

    if (token?.length > 60) {
      provider ??= "CLA";
      setToken(token);
      setHidden(true);
    } else if (code?.length > 6) {
      checkCode({ id: code });
      setSignUpCode(code);
    }

    if (provider) setProvider(provider);

    if (referral?.length === 8) {
      setReferralCode(
        "BUP-" + referral.slice(0, 6) + "-" + referral.slice(6, 8)
      );
      setHidden(true);
    }
  }, [query]);

  useEffect(() => {
    if (typeof data === "boolean" && !loading) {
      setIsValid(data);
      setError(!data);
    }
  }, [data, loading, CHECK_CODE]);

  return {
    error,
    hidden,
    isValid,
    loading,
    onBlur,
    provider,
    referralCode,
    signUpCode,
    token,
  };
}

const providers = [
  { id: "5f7c86228b190aa6f16fc438a0aa7b70", name: "CLA" },
  { id: "43f2b0dd09b2b91146fe726dcc47381b", name: "BCH" },
];
