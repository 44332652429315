import { useMutation } from "gql";
import { RESET_PASSWORD } from "mutations/session";
import { useSearchParams } from "next/navigation";
import { useMemo, useState } from "react";

function useRecovery() {
  const [resetPassword] = useMutation(RESET_PASSWORD);
  const [sentSuccess, setSentSuccess] = useState(false);
  const searchParams = useSearchParams();

  const [token, id] = useMemo(
    () => [searchParams.get("token"), searchParams.get("id")],
    [searchParams]
  );

  const submitResetPassword = async (variables) => {
    try {
      const response = await resetPassword(variables);
      setSentSuccess(true);
      return !!response;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  };

  const onSubmit = async (values, { setErrors }) => {
    const { password, confirmPassword } = values;

    if (password.length < 8) {
      setErrors({ password: true });
    } else if (confirmPassword !== password) {
      setErrors({ confirmPassword: true });
    } else {
      submitResetPassword({
        id,
        password,
        token,
      });
    }
  };

  return {
    disabled: !token || !id,
    onSubmit,
    sentSuccess,
  };
}

export default useRecovery;
